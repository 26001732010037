import styled, { css } from 'styled-components';
import { BREAKPOINT_M } from 'UI/globals/breakpoints';
import {
  LinkItem,
  LinksWrapper,
} from 'CORE__UI/apps/HeaderQuickLinksApp/core__header-quick-links';

const LinksWrapper_Ex = styled(LinksWrapper)`
  ${({ gameSelected }) =>
    gameSelected &&
    css`
      @media (max-width: ${BREAKPOINT_M}) {
        display: none;
      }
    `}
`;

const LinkItem_Ex = styled(LinkItem)`
  ${({ isSearch }) =>
    isSearch &&
    css`
      margin-top: 2px;
      margin-right: -4px;
    `}
`;
export { LinkItem_Ex as LinkItem, LinksWrapper_Ex as LinksWrapper };
