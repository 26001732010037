import React from 'react';
import ReactDOM from 'react-dom';
import { TranslationsProvider } from 'Services/core__services';
import ScDecoder from 'Services/json/core__decoder';
import { HeaderQuickLinksReact } from './components/core__header-quick-links-react';
export const Translations = new TranslationsProvider('HeaderQuickLinksApp');

class HeaderQuickLinksApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      Translations.get = properties.Translations.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations.getAll.bind(
        properties.Translations
      );
      return;
    }

    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    Translations.setAll(data);

    const ReactInstance = this.getReactApp();
    const jsx = <ReactInstance appConfig={this.appConfig} />;

    ReactDOM['render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }
  getReactApp() {
    return HeaderQuickLinksReact;
  }
}

export default HeaderQuickLinksApp;
