import styled, { css } from 'styled-components';
import { BRAND, GREYS } from 'UI/globals/colours';

export const LinksWrapper = styled.div`
  display: flex;
  height: 40px;
  padding-block: 8px;
  font-size: 14px;
  font-weight: 800;
  overflow: auto;
  justify-content: space-between;
`;
export const LinkItem = styled.a`
  display: flex;
  color: ${({ active }) => (active ? BRAND.secondary : GREYS.white)};
  margin-inline: 12px;
  line-height: 20px;
  white-space: nowrap;
  &:first-child {
    margin-left: 20px;
  }
  ${({ isSearch }) =>
    isSearch &&
    css`
      margin-top: 4px;
      margin-right: -4px;
    `}
`;
