export const manageClassNames = (
  mainSection,
  showQuickLinks,
  kycPrompt,
  isGameSelected
) => {
  if ((!showQuickLinks && !kycPrompt) || isGameSelected) {
    mainSection[0]?.classList?.add('without-quick-links');
  } else {
    mainSection[0]?.classList?.remove('without-quick-links');
  }
  if (showQuickLinks && kycPrompt) {
    mainSection[0]?.classList?.add('with-quick-links-and-kyc-prompt');
  } else {
    mainSection[0]?.classList?.remove('with-quick-links-and-kyc-prompt');
  }
  if (!showQuickLinks && kycPrompt) {
    mainSection[0]?.classList?.add('with-kyc-prompt-only');
  } else {
    mainSection[0]?.classList?.remove('with-kyc-prompt-only');
  }
  if (showQuickLinks && !kycPrompt) {
    mainSection[0]?.classList?.add('with-quick-links-only');
  } else {
    mainSection[0]?.classList?.remove('with-quick-links-only');
  }
};
