import {
  LinkItem as LinkItem_Core,
  LinksWrapper as LinksWrapper_Core,
} from 'CORE__UI/apps/HeaderQuickLinksApp/core__header-quick-links';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FSBCustomerSession,
  generateNoEventsDataArray,
  PubSub,
  PubsubEvents,
} from 'Services/core__services';
import { GLOBAL_PATH } from 'Services/global/core__constants';
import { manageClassNames } from '../core__header-quick-links-utils';
import {
  LinkItem as LinkItem_Local,
  LinksWrapper as LinksWrapper_Local,
} from 'UI/apps/HeaderQuickLinksApp/HeaderQuickLinks';
import { getComponent } from 'Services/core__imports';
import useFSBSession from 'Services/hooks/core__useFSBSession';

const LinkItem = getComponent(LinkItem_Local, LinkItem_Core);
const LinksWrapper = getComponent(LinksWrapper_Local, LinksWrapper_Core);

export const HeaderQuickLinksReact = ({ appConfig }) => {
  const [kycPrompt, setKycPrompt] = useState(false);
  const [pathname, setPathname] = useState(false);
  const [gameSelected, setGameSelected] = useState(false);
  const quickNames = appConfig?.quicknames;
  const quickLinks = appConfig?.quicklinks;
  const showQuickLinks = appConfig?.showquicklinks;
  const hideCasinoQuickNamesOnLogout = appConfig?.hidecasinoquicknamesonlogout?.split(
    ','
  );
  const quickObjectsArray = generateNoEventsDataArray(quickNames, quickLinks);
  const data = useFSBSession();
  const checkAndSetKycPrompt = data => {
    const element = document.getElementById('kycprompt');
    const showVerifyPopup = data && (data?.showKycPrompt ?? !data?.kycApproved);
    setKycPrompt(element?.style.display !== 'none' && showVerifyPopup);
  };
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    // Pubsub event listen for when the session is being updated
    const sessionUpdated = PubSub.listen(PubsubEvents.SESSION_UPDATED, () => {
      const session = FSBCustomerSession.getSession();
      if (session) {
        setLoggedIn(session.accessToken);
      }
    });

    // Pubsub event listen for when the session is being created
    const sessionCreated = PubSub.listen(PubsubEvents.SESSION_CREATED, () => {
      const session = FSBCustomerSession.getSession();
      if (session) {
        setLoggedIn(session.accessToken);
      }
    });

    // in the case a session isn't updated or created but page manages to change - check conditions on render
    const session = FSBCustomerSession.getSession();
    if (session) {
      setLoggedIn(session.accessToken);
    }

    return () => {
      sessionUpdated?.unsubscribe();
      sessionCreated?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const checkKycPrompt = PubSub.listen(
      PubsubEvents.KYC_PROMPT_ACTIVE,
      data => {
        checkAndSetKycPrompt(data);
      }
    );
    return () => {
      checkKycPrompt.unsubscribe();
    };
  }, []);

  useEffect(() => {
    checkAndSetKycPrompt(data);
  }, [data]);

  useEffect(() => {
    const mainSection = document.getElementsByClassName('main');
    const heaserQuickLinksSection = document.getElementsByClassName(
      'header-quick-links'
    );

    if (heaserQuickLinksSection && kycPrompt) {
      heaserQuickLinksSection[0]?.classList?.add('header-with-kyc-prompt');
    } else {
      heaserQuickLinksSection[0]?.classList?.remove('header-with-kyc-prompt');
    }
    const isGameSelected = window?.location.pathname.split('/')[2] === 'game';
    manageClassNames(mainSection, showQuickLinks, kycPrompt, isGameSelected);
    setGameSelected(isGameSelected);
  }, [kycPrompt]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPathname(window?.location.pathname);
  }, []);

  return (
    <React.Fragment>
      {showQuickLinks && (
        <LinksWrapper gameSelected={gameSelected}>
          {appConfig?.showquicksearch && (
            <LinkItem
              active={pathname === GLOBAL_PATH.SEARCH}
              isSearch={true}
              href={GLOBAL_PATH.SEARCH}
            >
              <span>
                <i className="icon-search"></i>
              </span>
            </LinkItem>
          )}
          {quickObjectsArray
            ?.filter(
              item =>
                loggedIn || !hideCasinoQuickNamesOnLogout.includes(item.name)
            )
            ?.map((item, id) => (
              <LinkItem
                active={pathname === item.link}
                key={id}
                href={item.link}
              >
                {item.name}
              </LinkItem>
            ))}
        </LinksWrapper>
      )}
    </React.Fragment>
  );
};
HeaderQuickLinksReact.propTypes = {
  appConfig: PropTypes.object,
};
